<template>
  <div class="stardonations">
    <div class="stardonations__text">
      <div class="stardonations__logos">
        <img :src="setPartnerLogo" alt="logo" />
        <div class="stardonations__logos--divider"></div>
        <img src="/images/logos/maw/logo.png" alt="logo" />
      </div>
      <div class="stardonations__title">
        <p>Juntos, podemos criar esperança.</p>
      </div>
      <div class="stardonations__subtitle">
        Ao fazer um donativo à Make-A-Wish, está a contribuir para a realização
        dos desejos de crianças gravemente doentes.
      </div>
      <div class="stardonations__aditional-subtitle">
        Juntos, a transformar vidas, um desejo de cada vez.
      </div>
      <div class="stardonations__star">
        <Star :starPercentage="setStarPercentage" />
      </div>
      <div class="stardonations__legend">
        1€ de donativo = 1 estrela
      </div>
      <div class="stardonations__starcount">
        Faltam {{ setStarValue }} estrelas para que o próximo desejo seja
        realizado!
      </div>
      <button @click="scroll" class="stardonations__button">
        <span>Faça já o seu donativo</span>
      </button>
    </div>
    <div class="stardonations__mobile-header">
      <div class="stardonations__mobile-header--logos">
        <img :src="setPartnerLogo" alt="logo" />
        <div class="stardonations__logos--divider"></div>
        <img src="/images/logos/maw/logo.png" alt="logo" />
      </div>
      <div class="stardonations__mobile-header--title">
        <p>Juntos podemos criar esperança.</p>
      </div>
    </div>
    <picture class="stardonations__image">
      <source media="(min-width:650px)" :srcset="setPartnerBackgroundImage" />
      <source
        media="(min-width:431px)"
        :srcset="setPartnerBackgroundImageMobile"
      />
      <img
        :src="setPartnerBackgroundImage"
        alt="background-image"
        style="width:100%;"
      />
    </picture>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "stardonations",

  components: {
    Star: () => import("@/components/star")
  },

  computed: {
    ...mapGetters({
      getQuantity: "partner/getQuantity",
      getMaxQuantity: "partner/getMaxQuantity",
      getPartnerLogo: "partner/getPartnerLogo",
      getPartnerBackgroundImage: "partner/getPartnerBackgroundImage",
      getPartnerBackgroundImageMobile: "partner/getPartnerBackgroundImageMobile"
    }),
    setPartnerBackgroundImage() {
      const bckImg = this.getPartnerBackgroundImage
        ? this.getPartnerBackgroundImage
        : "/images/background/MAW_main-lg.png";
      return bckImg;
    },
    setPartnerBackgroundImageMobile() {
      const bckImgMobile = this.getPartnerBackgroundImageMobile
        ? this.getPartnerBackgroundImageMobile
        : "/images/background/MAW_main_mobile.png";
      return bckImgMobile;
    },
    setPartnerLogo() {
      return this.getPartnerLogo;
    },
    setStarValue() {
      return this.getMaxQuantity - this.getQuantity;
    },
    setStarPercentage() {
      const value = (this.getQuantity * 100) / this.getMaxQuantity;
      return `${value.toFixed()}%`;
    }
  },
  methods: {
    scroll() {
      const element = document.getElementById("donations");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style scoped lang="scss">
.stardonations {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: $color-make-a-wish-dark-grey;

  @media (min-width: $breakpoint-xl) {
    flex-direction: row;
  }

  &__text {
    position: absolute;
    width: 90%;
    top: 530px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "latoregular";
    font-size: 15px;
    line-height: 18px;
    z-index: 15;
    background-color: $color-white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    margin: 0 20px;
    padding: 30px 20px 29px;

    @media (min-width: $breakpoint-sm) {
      font-size: 16px;
      left: 20px;
      top: 780px;
    }

    @media (min-width: $breakpoint-md) {
      top: 890px;
    }

    @media (min-width: $breakpoint-lg) {
      z-index: 2;
      width: 620px;
      left: 71px;
      top: 20px;
      bottom: auto;
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__logos {
    display: none;

    @media (min-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
      justify-content: center;

      :not(:first-child) {
        margin-left: 22px;
      }
    }

    &--divider {
      width: 0px;
      height: 30.48px;
      border: 1px solid $color-make-a-wish-light-grey;

      @media (min-width: $breakpoint-sm) {
        height: 45px;
      }
    }

    img {
      width: 135px;
      height: 33px;
      object-fit: contain;

      @media (min-width: $breakpoint-sm) {
        width: 199px;
        height: 49px;
      }
    }
  }

  &__title {
    display: none;
    font-family: "droid_serifbold";

    @media (min-width: $breakpoint-sm) {
      width: 100%;
    }

    @media (min-width: $breakpoint-lg) {
      display: block;
      font-size: 36px;
      line-height: 36px;
      padding: 48px 110px 0;
      color: $color-make-a-wish-blue;
      margin-bottom: 40px;
    }

    @media (min-width: $breakpoint-xxl) {
      padding: 15px 110px 0;
      margin-bottom: 15px;
    }

    @media (min-width: $breakpoint-1080p) {
      padding: 48px 110px 0;
      margin-bottom: 40px;
    }
  }

  &__subtitle {
    width: 100%;
    font-size: 16px;
    line-height: 19px;

    @media (min-width: $breakpoint-md) {
      width: 312px;
    }

    @media (min-width: $breakpoint-lg) {
      width: 612px;
      padding: 0 72px;
    }
  }

  &__aditional-subtitle {
    width: 100%;
    margin: 12px auto 0;
    padding: 0 53px;
    font-size: 16px;
    line-height: 19px;

    @media (min-width: $breakpoint-lg) {
      margin: 5px auto 0;
    }
  }

  &__legend {
    font-family: "latobold";
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    @media (min-width: $breakpoint-lg) {
      line-height: 16px;
      margin: 0;
    }

    @media (min-width: $breakpoint-xxl) {
      margin: -5px 0 0;
    }
  }

  &__starcount {
    width: 100%;
    font-family: "latobold";
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0 32px;

    @media (min-width: $breakpoint-lg) {
      line-height: 16px;
      margin-bottom: 40px;
    }

    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 20px;
    }
  }

  &__button {
    margin: 20px 0;
    font-family: "latobold";
    font-size: 18px;
    line-height: 22px;

    color: $color-white;
    background: $color-make-a-wish-blue;
    box-shadow: 4px 4px 4px rgba(255, 181, 73, 0.3);
    border-radius: 16px;
    border: 0;
    padding: 16px 31px;

    @media (min-width: $breakpoint-md) {
      font-size: 20px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 28px;
      line-height: 34px;
      margin: 0;
      padding: 16px 66px;
    }

    @media (min-width: $breakpoint-1080p) {
      margin: 20px 0;
    }
  }

  &__image {
    z-index: 0;
    height: 100%;
    width: 100%;
    min-height: 561px;

    @media (min-width: $breakpoint-lg) {
      max-height: 900px;
    }

    @media (min-height: $breakpoint-xxl) {
      max-height: 800px;
    }

    @media (min-width: $breakpoint-1080p) {
      max-height: 100%;
    }
  }

  &__mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 47px 32px;
    background: $color-grey-wild-sand;

    @media (min-width: $breakpoint-lg) {
      display: none;
    }

    &--logos {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 32px;

      :not(:first-child) {
        margin-left: 22px;
      }

      @media (min-width: $breakpoint-lg) {
        display: none;
      }

      img {
        width: 135px;
        height: 33px;
        object-fit: contain;

        @media (min-width: $breakpoint-sm) {
          width: 199px;
          height: 49px;
        }
      }
    }

    &--title {
      position: relative;
      display: flex;
      justify-content: center;
      font-family: "droid_serifbold";
      width: 100%;
      font-size: 34px;
      line-height: 40px;
      color: $color-make-a-wish-blue;
      margin-bottom: 32px;

      @media (min-width: $breakpoint-lg) {
        display: none;
      }
    }
  }
}
</style>
